<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-05 09:42:28
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-09-06 09:38:00
 * @FilePath: \official_website\src\views\decennial\Decennial.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 品信十周年 -->
<template>
  <div id="decennial">
    <Header></Header>
    <div class="decennial-content">
      
      <!-- <el-carousel
        trigger="click"
        :interval="cutTime"
        :autoplay="autoplay"
        height="53rem"
      >
        <el-carousel-item>
          <div class="banner"></div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="video-box">
            <video
              ref="videoPlayer"
              :controls="isIn"
              poster="@/assets/image/decennial/cover.jpg"
            >
              <source
                src="@/assets/image/decennial/px-decennial.mp4"
                type="video/mp4"
              />
              您的浏览器不支持Video标签。
            </video>
            <i
              v-show="!isPlay"
              class="play-btn el-icon-video-play"
              @click="playVideo"
            ></i>
            <i
              v-show="isPlay"
              class="play-btn el-icon-video-pause"
              @click="pauseVideo"
            ></i>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <div class="banner">
        
      </div>
      <!-- <div class="video-box">
        <video controls>
          <source src="@/assets/image/decennial/px-decennial.mp4" type="video/mp4">
            您的浏览器不支持Video标签。
          </video>
      </div> -->
      <div class="introduce">
        <div class="title">十周年</div>
        <div class="line"></div>
        <img src="@/assets/image/decennial/theme.jpg" alt="" />
      </div>
      <div class="timeline">
        <ul class="timeline-box">
          <div class="vertical-line"></div>
          <li class="time-item left">
            <div class="time">2023</div>
            <p class="title wow animate__animated animate__fadeInLeft">
              全自动推片染色机、全自动血液细胞形态AI扫描分析系统、DNA倍体特殊染色机全线上市，公司正式迈入产品多元化新时代
            </p>
          </li>
          <li class="time-item right">
            <div class="time">2022</div>
            <p
              class="title wow animate__animated animate__fadeInRight"
              data-wow-delay="0.3s"
            >
              数字切片扫描仪系列获批，远程病理诊断解决方案上市
            </p>
          </li>
          <li class="time-item left">
            <div class="time">2021</div>
            <p
              class="title wow animate__animated animate__fadeInLeft"
              data-wow-delay="0.6s"
            >
              吉米娜CR-SE600上市，与金域医学等多家全国型第三方实验室达成深度合作
            </p>
          </li>
          <li class="time-item right">
            <div class="time">2020</div>
            <p
              class="title wow animate__animated animate__fadeInRight"
              data-wow-delay="0.9s"
            >
              全自动核型分析仪上市，首都医科大学附属北京胸科医院等标杆医院建立
            </p>
          </li>
          <li class="time-item left">
            <div class="time">2019</div>
            <p
              class="title wow animate__animated animate__fadeInLeft"
              data-wow-delay="1.2s"
            >
              产品快速布局全国，多中心实验圆满完成，两癌筛查管理平台上市
            </p>
          </li>
          <li class="time-item right">
            <div class="time">2018</div>
            <p
              class="title wow animate__animated animate__fadeInRight"
              data-wow-delay="1.5s"
            >
              四代产品吉米娜上市，公司搬迁新址
            </p>
          </li>
          <li class="time-item left">
            <div class="time">2017</div>
            <p
              class="title wow animate__animated animate__fadeInLeft"
              data-wow-delay="1.8s"
            >
              三代产品鹰眼上市
            </p>
          </li>
          <li class="time-item right">
            <div class="time">2013</div>
            <p
              class="title wow animate__animated animate__fadeInRight"
              data-wow-delay="2.1s"
            >
              公司成立
            </p>
          </li>
        </ul>
      </div>
      <div class="send-word">
        <div class="title">创始人寄语</div>
        <div class="line"></div>
        <div class="send-content">
          <div class="founder">
            <img src="@/assets/image/decennial/founder.jpg" alt="创始人图片" />
            <div class="text">
              湖南品信生物工程有限公司创始人<span>刘剑锋</span>
            </div>
          </div>

          <div class="blessing wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
            亲爱的朋友们：<br />
            <p style="text-indent: 2em">
              回首公司十年发展点滴，我们从最开始的几个人/十几人的团队到现在近200人的规模，从最开始的第一台“鹰眼-全自动细胞DNA定量分析系统”到现在建立了“试剂耗材、制片染色、AI分析、远程管理平台”全产品线，一路上经历了几次大的挑战，也完成了无数次蜕变，但变的只是时间，不变的是我们的初心。十年来，我们始终秉持“品为先
              信至上”的理念，脚踏实地，不断探索，一步一个脚印地走了过来。十年来，我们以“病理诊断”为核心，通过“AI+数字化医疗”的双轮驱动
              ，致力于为疾病的早期筛查和诊断，提供“自动化、数字化、智能化、平台化”的完整解决方案。
            </p>
            <p style="text-indent: 2em">十年岁月峥嵘，十年春华秋实。</p>
            <p style="text-indent: 2em">
              十年的发展，我们取得了一些成绩，获得了多个产品注册证，拥有多个国家及省市创新平台，与10多家大型三甲医院开展科研合作，打造产学研用一体化发展。同时，布局了全国市场，打造了300+标杆医院，参与甘肃省两癌、湖南省两癌等政府筛查项目，参与了国家5G+健康平台试点项目，并启动了血液、微生物、甲状腺、尿液等新领域的创新研发，在病理行业建立了一定品牌知名度。这些都为公司的下一阶段的发展打下了良好的基础。可以说，公司这十年发展离不开各位股东、各位专家、合作伙伴的大力支持。感谢我们一路上的伙伴和朋友们，没有你们的支持和厚爱，就没有我们品信的今天，也希望在未来大家能一如既往地支持我们品信，彼此深度合作，一起为我国病理行业的发展贡献一份力量！
              在时间的长河中，十年不过是浪花一朵。但对发展中的企业而言，犹如逆水行舟，不进则退。现在，公司进入到快速发展关键阶段，未来挑战与机遇并存。从外部环境来看，后疫情时代，经济复苏不及预期，医疗行业集采的不断推进，企业之间竞争越来越激烈，但我们也看到随着我国老龄化加剧，癌症的发病率增长，癌症早筛及诊断需求激增，其次，国家对两癌筛查的大力支持，对人工智能发展的鼓励，都是利好企业未来的发展，可以说，我们在一个对的赛道上！未来，我们品信也将继续攻坚克难、迎难而上、攀登下一座高峰！
              最后，我代表公司全体员工祝大家身体健康、工作顺利、万事如意！谢谢！
            </p>
          </div>
        </div>
      </div>
      <div class="partners">
        <div class="theme wow animate__animated animate__fadeInLeft">
          常年与国内多家大型医院服务合作
        </div>
      </div>
      <div class="employee-story" v-if="false">
        <div class="story-title">
          <div class="title">员工故事</div>
          <div class="line"></div>
        </div>
        <div class="employee-box">
          <div class="left">
            <div class="title">这是标题</div>
            <p class="illustrate">
              年，是一个个“福”字，一副副春联，是大街小巷张灯结彩的喜庆；是全家欢喜忙碌年夜饭的团圆氛围，是无论何地，是否相识，充斥耳畔新春问候的温暖……佳节团圆人欢乐，帝迈售后守护幸福年，服务不打烊。我们品信也将继续攻坚克难、迎难而上、攀登下一座高峰！
              最后，我代表公司全体员工再一次向大家的到来表示衷心的感谢！祝大家身体健康、工作顺...
            </p>

            <div class="mores">
              <div class="more-line"></div>
              <div class="more-text">查看更多</div>
            </div>
          </div>
          <div class="right">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="(item, index) in swiperData" :key="index">
                <img :src="item.src" alt="" />
              </swiper-slide>
            </swiper>
            <div class="swiper-footers">
              <div class="numbers">1 / {{ swiperData.length }}</div>
              <div class="arrs">
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="related-cases" v-if="false">
        <!-- <swiper :options="swiperRelatedOptions">
          <swiper-slide v-for="(item, index) in 12" :key="index">
            {{ index + 1 }}
          </swiper-slide>
        </swiper> -->
        <div class="title">客户案例</div>
        <div class="line"></div>
        <div class="related-box"></div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import "swiper/css/swiper.min.css";
import {
  Swiper,
  SwiperSlide,
} from "../../../node_modules/vue-awesome-swiper/dist/vue-awesome-swiper";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperData: [
        // {
        //   title: "液基制片染色一体机",
        //   src: require("@/assets/image/decennial/employee.png"),
        // },
        // {
        //   title: "液基膜式制片机",
        //   src: require("@/assets/image/decennial/employee.png"),
        // },
        // {
        //   title: "细胞学TCT人工智能辅助诊断",
        //   src: require("@/assets/image/decennial/employee.png"),
        // },
        // {
        //   title: "远程病理诊断解决方案",
        //   src: require("@/assets/image/decennial/employee.png"),
        // },
      ],
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 52,
        // freeMode: true,
        // direction: "horizontal",
        slideToClickedSlide: true, //滑动到点击的slide
        // centeredSlides: true, //当前slide居中
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          // 前进后退
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperRelatedOptions: {
        // slidesPerView: "auto",
        slidesPerView: 3,
        slidesPerColumn: 2, //显示2行
        // slidesPerGroup: 6,
        slidesPerColumnFill: "row", //行布局
        // autoplay: true, //等同于以下设置
        // loop: true,
        // grid: {
        //   rows: 2,
        // },
        spaceBetween: 30,
      },
      autoplay: true, //是否自动切换
      isPlay: false, //播放状态
      isIn: false, //鼠标是否位于播放器内
      cutTime: 3000, //轮播时间，单位毫秒
      videoSrc: require("@/assets/image/decennial/cover.jpg"),
    };
  },
  created() {},
  mounted() {
    var that = this;
    // that.listenVideo();

    that.$nextTick(() => {
      new that.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    footSlideTo() {},
    //监听视频的播放、暂停、播放完毕等事件
    listenVideo() {
      //监听播放
      this.$refs.videoPlayer.addEventListener("play", () => {
        this.autoplay = false;
        this.isPlay = true;
      });
      //监听暂停
      this.$refs.videoPlayer.addEventListener("pause", () => {
        this.autoplay = true;
        this.isPlay = false;
      });
      //监听播放完毕
      this.$refs.videoPlayer.addEventListener("ended", () => {
        //时间归零
        this.autoplay = true;
        this.isPlay = false;
        this.$refs.videoPlayer.setAttribute("poster", this.videoSrc);
      });
    },
    //播放视频
    playVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      this.$refs.videoPlayer.play();
    },
    //暂停视频
    pauseVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      this.$refs.videoPlayer.pause();
    },
  },
};
</script>
    
<style lang="scss">
#decennial {
  .decennial-content {
    padding-top: 5rem;
    width: 100%;
    .el-carousel__item {
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        position: fixed;
        display: inline-block;
        vertical-align: baseline;
        object-fit: fill; //消除留白
      }
      .play-btn {
        font-size: 60px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 50;
        transform: translate(-50%, -50%);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .el-carousel__arrow {
      width: 3.5rem;
      height: 3.5rem;
      // background-color: rgba(31, 133, 132, 0.4)!important;
      [class^="el-icon-"],
      [class*=" el-icon-"] {
        font-size: 2rem !important;
      }
    }
    .el-carousel__button {
      width: 2.5rem;
      height: 0.25rem;
    }
    .banner {
      width: 100%;
      height: 53rem;
      background-image: url("../../assets/image/decennial/banner.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      animation-name: scaleDraw; /*关键帧名称*/
      animation-timing-function: ease; /*动画的速度曲线*/
      animation-duration: 3s; /*动画所花费的时间*/
      // background-size: 105%;
    }
    @keyframes scaleDraw {
      /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
      0% {
        // transform: scale(1); /*开始为原始大小*/
        background-size: 110%;
      }
      // 50% {
      //   // transform: scale(1.1);
      //   background-size: 110%;
      // }
      100% {
        // transform: scale(1);
        background-size: 100%;
      }
    }
    .video-box {
      video {
        width: 100%;
      }
    }
    .introduce {
      padding: 5rem 8%;
      .title {
        text-align: center;
        font-size: 1.8rem;
        font-family: "SourceHanSansSC-Medium";
      }
      .line {
        width: 6.25rem;
        height: 0.375rem;
        background: #0b847b;
        margin: 0.5rem auto 1.875rem;
      }
      img {
        width: 100%;
      }
    }
    .timeline {
      padding: 0 15%;
      background-image: url("../../assets/image/decennial/time-bg.png");
      box-sizing: cover;
      background-position: center center;
      .timeline-box {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 3.125rem;
      }
      .vertical-line {
        position: absolute;
        left: 49.97%;
        top: 6.25rem;
        width: 0.125rem;
        height: calc(100% - 14.375rem);
        background-color: #0b847b;
      }
      .time-item {
        width: 50%;
        position: relative;
        .time {
          padding-bottom: 0.5rem;
          font-size: 1.6rem;
          color: #0b847b;
          font-family: "SourceHanSansSC-Medium";
          border-bottom: 2px solid #0b847b;
        }
        .title {
          padding-top: 0.5rem;
          font-size: 1.2rem;
          line-height: 2rem;
          height: 4.5rem;
          font-family: "OPlusSans3-Regular";
        }
      }
      .left {
        .time {
          &::after {
            content: "";
            display: block;
            width: 0.625rem;
            height: 0.625rem;
            background-color: #0b847b;
            position: absolute;
            right: -0.3125rem;
            top: 2.5rem;
            border-radius: 50%;
            box-shadow: 0 0 5px 1px #0b847b;
          }
        }
      }
      .right {
        padding-left: 50%;
        .time {
          text-align: right;
          &::after {
            content: "";
            display: block;
            width: 0.625rem;
            height: 0.625rem;
            background-color: #0b847b;
            position: absolute;
            left: calc(50% - 0.3125rem);
            top: 2.5rem;
            border-radius: 50%;
            box-shadow: 0 0 5px 1px #0b847b;
          }
        }
        .title {
          text-align: right;
        }
      }
    }
    .send-word {
      padding: 5rem 8%;
      .title {
        text-align: center;
        font-size: 1.8rem;
        font-family: "SourceHanSansSC-Medium";
      }
      .line {
        width: 6.25rem;
        height: 0.375rem;
        background: #0b847b;
        margin: 0.5rem auto 1rem;
      }
      .send-content {
        .founder {
          width: 30%;
          float: left;
          margin-right: 6.25rem;
          margin-bottom: 6.25rem;
          img {
            width: 100%;
          }
          .text {
            text-align: center;
            font-size: 1rem;
            font-family: "OPlusSans3-Regular";
            padding-top: 1rem;
            span {
              display: inline-block;
              margin-left: 0.8rem;
            }
          }
        }
        .blessing {
          font-size: 1.2rem;
          line-height: 3.5rem;
          font-family: "OPlusSans3-Regular";
        }
      }
    }
    .partners {
      width: 100%;
      height: 45rem;
      margin-bottom: 5rem;
      background-image: url("../../assets/image/decennial/partners.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding: 5% 8%;
      .theme {
        width: 20rem;
        font-size: 2.2rem;
        font-family: "OPlusSans3-Regular";
        line-height: 4rem;
      }
    }
    .employee-story {
      padding: 3.125rem 8%;
      position: relative;
      .story-title {
        position: absolute;
        left: 8%;
        top: 3.125rem;
        .title {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 10px;
        }
        .line {
          width: 6.25rem;
          height: 0.375rem;
          background: #0b847b;
        }
      }
      .employee-box {
        position: relative;
        height: 31.25rem;
        display: flex;
        --swiper-navigation-color: #0b847b; /* 单独设置按钮颜色 */
        --swiper-navigation-size: 1.5rem; /* 设置按钮大小 */
        .left {
          width: 31%;
          padding-top: 6.25rem;
          margin-right: 4%;
          .title {
            font-size: 1.4rem;
            font-family: "SourceHanSansSC-Medium";
            margin-bottom: 1.25rem;
          }
          .illustrate {
            font-size: 1rem;
            line-height: 2rem;
            font-family: "OPlusSans3-Regular";
            margin-bottom: 4.7rem;
          }
          .mores {
            display: flex;
            align-items: center;
            .more-line {
              width: 70%;
              height: 3px;
              margin-right: 10%;
              background-color: #0b847b;
            }
            .more-text {
              width: 20%;
              color: #0b847b;
            }
          }
        }
        .right {
          width: 65%;
          .swiper-slide {
            height: 23.75rem;
            img {
              width: 40.625rem;
              height: 23.75rem;
              border-radius: 1.875rem;
            }
          }
          .swiper-footers {
            position: relative;
            height: 3.75rem;
            margin-top: 1.25rem;
            display: flex;
            align-items: center;
            padding: 0 1.25rem;
            .numbers {
              height: 100%;
              line-height: 3.75rem;
              font-size: 1rem;
              font-family: "OPlusSans3-Regular";
            }
            .arrs {
              position: absolute;
              left: 50%;
              width: 8.75rem;
              height: 100%;
              .swiper-button-prev,
              .swiper-button-next {
                width: 2.8rem;
                height: 2.8rem;
                top: 35%;
                border-radius: 50%;
                background-color: #eee;
              }
              .swiper-button-next:after,
              .swiper-button-prev:after {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .related-cases {
      padding: 5rem 8%;
      .title {
        text-align: center;
        font-size: 1.8rem;
        font-family: "SourceHanSansSC-Medium";
      }
      .line {
        width: 6.25rem;
        height: 0.375rem;
        background: #0b847b;
        margin: 0.5rem auto 1rem;
      }
      .swiper-wrapper {
        // flex-wrap: wrap;
        .swiper-slide {
          height: 18.75rem;
          background-color: pink;
          margin-bottom: 1.875rem;
          // &:nth-of-type(3n) {
          //   margin-right: 0!important;
          // }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>